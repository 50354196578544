import React from 'react'
import {Link} from 'gatsby'
import serviceIcon7 from '../../assets/images/services/service-icon7.png'
import serviceIcon8 from '../../assets/images/services/service-icon8.png'
import serviceIcon9 from '../../assets/images/services/service-icon9.png'
import serviceIcon10 from '../../assets/images/services/service-icon10.png'
import serviceIcon11 from '../../assets/images/services/service-icon11.png'
import serviceIcon12 from '../../assets/images/services/service-icon12.png'
import serviceShape4 from '../../assets/images/services/service-shape4.png'

const OurServices = () => {
    return (
        <section className="services-area ptb-100">
            <div className="container">
                <div className="section-title">
                    <h2>Our Services</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={serviceIcon7} alt="services" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Target Analysis
                                </Link>
                            </h3>
                            <p>You will receive a complete professional SEO Keyword Report within 1-day. Keyword research is the process of finding and analyzing search terms that people enter into search engines with the goal of using that data to drive more people onto your website.</p>
                            <Link to="/service-details" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                            </Link>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={serviceIcon8} alt="services" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Keyword Research
                                </Link>
                            </h3>
                            <p>Are you in search of SEO keyword research that boosts your business or website! Then you are in the right place. We are here with almost more than 1000 satisfied customers with excellent reviews.</p>
                            <Link to="/service-details" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                                
                            </Link>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item ">
                            <div className="icon">
                                <img src={serviceIcon9} alt="services" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Continuous Testing
                                </Link>
                            </h3>
                            <p>This project is for fixing bugs and extend your existing iOS mobile application project functionality. We can do unit and regression testing of your iOS mobile application to deliver best and bug free fully working iOS mobile application</p>
                            <Link to="/service-details" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                                
                            </Link>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={serviceIcon10} alt="services" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Marketing Analysis
                                </Link>
                            </h3>
                            <p>You will get thorough and professional market research and analysis for relevant factors, covering all aspects. We will provide you market research report/market Info-graphic data/competition analysis. I can provide 100% data accuracy with quick support</p>
                            <Link to="/service-details" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                            </Link>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={serviceIcon11} alt="services" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Content Optimization
                                </Link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p>
                            <Link to="/service-details" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                            </Link>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-md-6 col-sm-12">
                        <div className="single-services-box-item">
                            <div className="icon">
                                <img src={serviceIcon12} alt="services" />
                            </div>
                            <h3>
                                <Link to="/service-details">
                                    Email Marketing
                                </Link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod ut labore et dolore magna aliqua.</p>
                            <Link to="/service-details" className="learn-more-btn">
                                <i className="flaticon-right"></i> 
                                Learn More
                            </Link>
                            <div className="shape">
                                <img src={serviceShape4} alt="services" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurServices